<style scoped>
  @media print {
    .no-print {
      display: none !important;
    }
  }

  .floating-button-wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  .floating-button {
    border-radius: 50%;
  }
</style>

<template>
  <b-dropdown
    :value="value"
    position="is-top-left"
    class="floating-button-wrapper"
  >
    <a
      slot="trigger"
      class="button floating-button is-success is-large"
      title="Add"
    >
      <b-icon icon="plus" />
    </a>

    <b-dropdown-item @click="addDepot">
      {{ $t('depots.sg') }}
    </b-dropdown-item>
    <b-dropdown-item @click="addContact">
      {{ $t('common.contact') }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactForm from '@/components/Contacts/Form'
import DepotForm from './DepotForm'

export default {
  methods: {
    addDepot () {
      const id = new Date().toString()
      this.$store.dispatch('currentDoc/set', {
        field: `depots.${id}`,
        value: {
          id,
          created_by: _.pick(this.user, ['id', 'name']),
          name: '',
          address: '',
          city: '',
          zip: '',
          country: ''
        }
      })

      this.$buefy.modal.open({
        parent: this,
        component: DepotForm,
        hasModalCard: true,
        width: 400,
        height: 400,
        props: {
          id
        }
      })
    },
    async addContact () {
      const self = this
      const collectionPath = 'contacts'
      let options = []

      const id = new Date().toString()
      this.$store.dispatch('currentDoc/set', {
        field: `${collectionPath}.${id}`,
        value: {
          id,
          name: '',
          email: '',
          phone: ''
        }
      })

      this.$buefy.modal.open({
        parent: this,
        component: ContactForm,
        hasModalCard: true,
        fullScreen: true,
        props: {
          id,
          options,
          collectionPath
        }
      })
    }
  }
}
</script>
