<template>
  <a-field
    :field="field"
    :label="label"
  >
    <b-autocomplete
      :data="availableOptions"
      :field="optionLabel"
      :value="value"
      :name="optionLabel"
      open-on-focus
      autocomplete="off"
      @typing="onTyping"
      @select="emitSelectedObject"
    >
      <template #default="props">
        {{ props.option[optionLabel] }}
      </template>
    </b-autocomplete>
  </a-field>
</template>

<script>

import _ from 'lodash'

import Mixin from '@/components/Form/Mixin'
import AutocompleteMixin from '@/components/Form/AutocompleteMixin'
import AField from '@/components/Form/AField'

export default {
  components: {
    AField
  },
  mixins: [Mixin, AutocompleteMixin],
  computed: {
    computedOptions () {
      return _.filter(_.map(this.optionsArray, item => _.pick(item, ['id', 'name', 'email', 'phone'])), item => item.name)
    },
    remainingOptions () {
      return _.filter(this.computedOptions, item => !_.find(this.value, { id: item.id }))
    }
  },
  methods: {
    emitSelectedObject (value) {
      this.$emit('select', value)
      this.typing = value.name
    },
    onTyping (input) {
      this.typing = input
      this.update(input)
    }
    /* add (item) {
      let newValues = _.clone(this.value)
      const newItem =
      newValues.push(newItem)
      console.log('updating', newValues)
      this.update(newValues)
    },
    remove (item) {
      const newValues = _.remove(this.value, { id: item.id })
      console.log('updating', newValues)
      this.update(newValues)
    } */
  }
}
</script>
