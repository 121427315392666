<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ computedHeader }}
      </p>
    </header>

    <section class="modal-card-body">
      <a-card
        :editable="false"
      >
        <autocomplete
          :field="`${collectionPath}.${id}.name`"
          :label="$t('components.contacts.form.name')"
          option-label="name"
          :options="options"
          open-on-focus
          @select="selectExistingContact"
        />

        <a-input
          :field="`${collectionPath}.${id}.email`"
          type="email"
          :label="$t('components.contacts.form.email')"
        />

        <a-input
          :field="`${collectionPath}.${id}.phone`"
          :label="$t('components.contacts.form.phone')"
        />
      </a-card>
    </section>
    <footer class="modal-card-foot">
      <b-button
        type="is-danger"
        icon-left="delete"
        @click="remove"
      >
        {{ $t('common.delete') }}
      </b-button>
      <button
        class="button is-success"
        type="button"
        @click="close"
      >
        <span>{{ $t('common.ok') }}</span>
      </button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Autocomplete from './Autocomplete'

export default {
  components: {
    Autocomplete
  },
  props: {
    id: {
      required: true
    },
    width: {
      default: '800px'
    },
    options: {
      type: Array|Object,
      default: () => []
    },
    collectionPath: {
      type: String,
      default: 'contacts'
    },
    permanentDocument: {
      type: Object,
      required: false
    },
    header: {
      required: false
    }
  },
  computed: {
    contact() {
      return _.get(this.doc, `${this.collectionPath}.${this.id}`)
    },
    computedHeader() {
      return this.header || this.$t('components.contacts.form.header')
    }
  },
  methods: {
    close () {
      if(this.contact && this.contact.name) {
        this.updatePermanent(this.contact)
      }
      this.$parent.close()
    },
    remove () {
      this.$store.dispatch(`${this.module}/delete`, `${this.doc.id}.${this.collectionPath}.${this.id}`)
      this.close()
    },
    selectExistingContact (value) {
      if(value) {
        let field = `${this.collectionPath}.${value.id}`

        this.$store.dispatch(`currentDoc/set`, {
          field,
          value
        })

        this.remove()
      }
    },

    updatePermanent(value) {
      if(this.permanentDocument) {
        const { collection, id, path } = this.permanentDocument
        let newObj = _.set({}, `${path}.${value.uid || this.id}`, value)
        newObj.id = id
        console.log('updating contact', newObj)
        this.$store.dispatch(`${collection}/set`, newObj)
      }
    }
  }
}
</script>

<style scoped>
.modal-card {
    width: auto;
}
.modal-card-body {
    width: auto;
}
</style>
